import React, {useState} from "react";
import Chevron from "./Chevron.jsx"
const Faq = ({faq}) => {

const [isActive, setIsActive] = useState("");
    return(
        <div className="w-screen"> 
            <button className="cursor-pointer transition duration-500 ease-in-out bg-white hover:bg-herobg flex justify-between items-center border border-1 border-herobg md:w-4/5 w-full lg:w-3/5 py-3 px-6 mx-auto" onClick = {() => (isActive === "") ? setIsActive("active") : setIsActive("")}>
                <p className="accordion-question pr-3 text-left"> 
                    {faq.question.question}
                </p>
                <Chevron fill="#555770" width="8" className={isActive ? "chevron active" : "chevron"} />
            </button>
            <div className={isActive ? "block" : "hidden"}>
                <div className="accordion-answer w-full md:w-4/5 lg:w-3/5 py-3 px-6 mx-auto">
                    {faq.answer.answer}
                </div>
            </div>
        </div>
    )
}


export default Faq;