import React from "react";
import Button from "./Button";
import ButtonSm from "./ButtonSm";

import Img from "gatsby-image";

const Demo = ({cards, title, titleAlign, display, bottomPadding}) => {

    const DemoCard = props => {
        const { url, summary, image, tag } = props
      
        return (
            <a href={url} target="_blank" rel="noopener noreferrer">
                <div className="flex flex-col justify-around h-full overflow-hidden hover:bg-nectarine hover:bg-opacity-25 p-4 lg:p-6 xl:p-8">
                    <h2 className="text-sm md:text-md text-center text-nectarine font-bold my-6">{summary}</h2>
                    <div className="rounded-md shadow-xl border border-customgray">
                        <Img fluid={image.fluid} alt={image.description} className="w-full rounded-md" />
                    </div>                    
                    <div className={`py-8 lg:pb-0 lg:pt-10`}>
                        <ButtonSm>LAUNCH DEMO</ButtonSm>
                    </div>
                </div>
            </a>
        )
    }

    return (
      <div className={`flex flex-col px-3 lg:px-12 xl:px-20 ${titleAlign} ${bottomPadding} pb-32 ${title? 'pt-20' : 'pt-0'} xl:pt-20 bg-cream`}>
        <h1 className="z-1">{title}</h1>
        <p className="font-bold pb-6">Launch the demo, click donate and enter your email so you can experience the full virtual card experience.</p>
          <div className="relative w-full h-full">
                <div className=" flex flex-col xl:flex-row  mx-auto items-center xl:items-stretch justify-center md:rounded-md lg:px-12">
                    { cards.map((card,index) => (
                        <div key={`demo-card-${index}`} className="w-full max-w-2xl p-5">
                            <DemoCard tag={card.tag} 
                                summary={card.description} 
                                image={card.image} 
                                url={card.link}
                            />        
                        </div>
                    ))}
                </div>
                <div className={`pt-10 ${display}`}>
                    <Button href={"/demos"}>See all demos</Button>
                </div>
          </div>  
      </div>
  
    )
}

export default Demo