import React from "react";
import BenefitsCard from "./BenefitsCard";
import Button from "./Button";
import beach from '../images/beach.png';


const Features = ({cards}) => {

    return (
      <div className="flex flex-col container text-center pt-20">
          <h1 className="pb-10 z-1">Features</h1>
          <div className="relative w-full h-full">
              <div className="flex flex-col lg:flex-row justify-between">
                    <div className="flex flex-col md:flex-row mx-auto justify-center">
                        { cards.map((card,index) => (
                            <BenefitsCard title={card.featureTitle} 
                                icon={card.icon.fluid} 
                                iconAlt={card.icon.description} 
                                benefitsList={card.featureBenefits}
                                key={`benefit-card-${index}`}
                            />
                        ))}
                    </div>
                  <div className="max-w-lg mx-auto">
                    <img src={beach} alt="graphic of a beach" className="max-w-full"/>
                    <p className="permanent-small text-blue-bright transform -translate-y-16">Life is a beach with Zurly</p>
                    <span className="pt-16"><Button href="/contact">Learn More</Button></span>
                  </div>
              </div>
          </div>  
      </div>
  
    )
    
}
    
export default Features