import React from "react";
import Button from "./Button";


const ContactBanner = () => {

    return (
      
      
      <div className="flex flex-col md:flex-row items-center px-6 lg:px-24 xl:px-40 justify-between py-12 bg-seagreen">
          <div className="text-center md:text-left mb-8">
            <h1 className="text-white">Let's get started</h1>
            <h4 className="">Have our representatives reach out to you</h4>
          </div>
          <div className="my-auto">
            <Button href="/contact">Get in touch</Button>
          </div>
      </div>
  
    )
    
}
    
export default ContactBanner