import Img from "gatsby-image"
import React from "react"
import ButtonSm from "../components/ButtonSm"

const Card = ({item}) => {
  return (
    <div className="flex flex-col justify-around bg-white h-full shadow-sm overflow-hidden group pb-3 rounded-md">
      <div className="group-hover:opacity-75 transition duration-150 ease-in-out">
        <Img fluid={item.node.featuredImage.fluid} alt={item.node.featuredImage.description} />
      </div>
      <div className="p-4 sm:p-5">
        <h3 className="sm:text-xl font-bold pb-3">{item.node.title}</h3>
        <p className="text-sm sm:text-base text-gray-700">{item.node.body.childMarkdownRemark.excerpt}</p>
      </div>
      <div className="mb-4 ml-4">
        <ButtonSm href={`/blog/${item.node.slug}`}>Read More</ButtonSm>
      </div>
    </div>
  )
}

export default Card

