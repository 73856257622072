import PropTypes from "prop-types"
import React from "react"

const ButtonSm = ({ children, href, ...params }) => {
  const className =
    "inline-block px-3 py-2 leading-snug border border-deeppurple text-xs rounded-md font-bold text-deeppurple bg-white opacity-75 hover:opacity-100 hover:shadow-md uppercase focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:truncate "

  if (href) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={className}
      >
        {children}  &nbsp;&nbsp;&nbsp; ⮕
      </a>
    )
  } else {
    return (
      <button className={className} {...params}>
        <div className="flex flex-row">
        <span>{children}</span><span>&nbsp;&nbsp;&nbsp; ⮕</span>
        </div>
      </button>
    )
  }
}
 

ButtonSm.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
}

ButtonSm.defaultProps = {
  href: null,
}

export default ButtonSm
