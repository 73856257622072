import React from 'react'
import Img from 'gatsby-image'

const BenefitsCard = ({title, icon, iconAlt, benefitsList}) => {
  
  
  return (
  
      <div className="px-3 align-top last:pb-0 pt-16 pb-8 w-1/4 min-w-xxs">
        <div className="custom-card py-5 pt-16 h-full w-full">
          <div className="absolute transform -translate-y-28 translate-x-24 rounded-full h-20 w-20 bg-herobg custom-icon-shadow">
          <Img fluid={icon} alt={iconAlt} className="h-8 w-8 mx-auto transform translate-y-6" />
          </div>
          <h4>{title}</h4>
          <ul className="py-4">
            {benefitsList.map(benefit => (
              <li key={benefit}><p className="text-center px-6 text-sm">{benefit}</p></li>
            ))}
          </ul>
        </div>
      </div>
    )
  
  }
  
  export default BenefitsCard
  