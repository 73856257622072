import React from "react";
import Button from "./Button";
import Faq from "./Faq";

const FaqWrapper = ({faqs}) => {


    return(
        <div className="flex flex-col">
        <h1 className="pb-10 z-1 text-center">FAQs</h1>
            {faqs.nodes.map((faq) => {
                return(<Faq faq={faq} key={faq.id}/>)
            })
            }
            <div className="mx-auto py-16">
                <Button href="/contact">Try for free</Button>
            </div>
        </div>
    )
}
    
export default FaqWrapper;