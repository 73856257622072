import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import Card from "./Card"
import Button from "./Button"


const Cards = ({ items, hideLastItemOnMobile = false }) => {
  return (
    <div className="container flex flex-col align-center">
      <h1 className="pb-12 text-center">Resources</h1>
      <div className="flex flex-wrap -mx-3 lg:-mx-6">
        {items.map((item,key) => (
          <div
            key={`card_container_${key}`}
            className={classNames("w-full sm:w-1/2 lg:w-1/3 p-3 md:p-6", {
              "last:hidden lg:last:block": hideLastItemOnMobile,
            })}
          >
            <Card item={item} />
          </div>
        ))}
      </div>
      <span className="text-center pt-20"><Button href="/resources">Keep learning</Button></span>
    </div>
  )
}

Cards.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Cards
