import React from "react";
import Img from 'gatsby-image';
import Button from './Button';

const FeaturesHero = ({h1,category,paragraph,image, altImage, button, href}) => {


    return(
      <div className="bg-lightpurple h-full lg:h-auto">
        <div className="px-6 lg:px-24 xl:px-40 pt-32 lg:pb-16 flex flex-col lg:flex-row text-center lg:text-left lg:justify-between w-screen">
          <div className="w-full lg:w-1/2 xl:w-2/5 my-auto flex-shrink">
            <h1>{h1}</h1>
            <h1 className="permanent">{category}</h1>
            <p className="pt-5 pb-12 text-black">{paragraph}</p>
            <Button href={href}>{button}</Button>
          </div>
          <div id="hero-image" className="w-full lg:w-1/2 p-6 xl:p-12 max-h-full">
            <Img fluid={image.fluid} alt={altImage} className="max-h-screen"/>
          </div>
        </div>
      </div>
    )
}


export default FeaturesHero
