import PropTypes from "prop-types"
import React from "react"

const Button = ({ children, href, ...params }) => {
  const className =
    `inline-block px-5 py-3 font-medium leading-snug border text-base rounded-md text-deeppurple bg-white border-deeppurple bg-opacity-75 hover:bg-opacity-100 hover:shadow-lg focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:truncate`

  if (href) {
    return (
      <a
        href={href}
        rel="noopener noreferrer"
        className={className}
      >
        {children}  &nbsp;&nbsp;&nbsp; ⮕
      </a>
    )
  } else {
    return (
      <button className={className} {...params}>
        <div className="flex flex-row">
        <span>{children}</span><span>&nbsp;&nbsp;&nbsp; ⮕</span>
        </div>
      </button>
    )
  }
}
 

Button.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
}

Button.defaultProps = {
  href: null,
}

export default Button
